var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    ("cv-modal " + _vm.carbonPrefix + "--modal"),
    ( _obj = {
      'is-visible': _vm.dataVisible
    }, _obj[(_vm.carbonPrefix + "--modal--danger")] = _vm.kind === 'danger', _obj ) ],attrs:{"data-modal":"","id":_vm.uid,"tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.onEsc.apply(null, arguments)},"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onExternalClick.apply(null, arguments)}}},[_c('div',_vm._b({ref:"modalDialog",class:[
      (_vm.carbonPrefix + "--modal-container"),
      ( _obj$1 = {}, _obj$1[(_vm.carbonPrefix + "--modal-container--" + _vm.internalSize)] = _vm.internalSize, _obj$1 ) ]},'div',_vm.dialogAttrs,false),[_c('div',{ref:"beforeContent",staticClass:"cv-modal__before-content",staticStyle:{"position":"absolute","height":"1px","width":"1px","left":"-9999px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusBeforeContent}}),_c('div',{class:(_vm.carbonPrefix + "--modal-header")},[_c('p',{class:(_vm.carbonPrefix + "--modal-header__label")},[_vm._t("label")],2),_c('p',{class:(_vm.carbonPrefix + "--modal-header__heading")},[_vm._t("title",function(){return [_vm._v("Modal Title")]})],2),_c('button',{ref:"close",class:(_vm.carbonPrefix + "--modal-close"),attrs:{"type":"button","aria-label":_vm.closeAriaLabel},on:{"click":_vm.onClose}},[_c('Close16',{class:(_vm.carbonPrefix + "--modal-close__icon")})],1)]),_c('div',{ref:"content",class:[(_vm.carbonPrefix + "--modal-content"), ( _obj$2 = {}, _obj$2[(_vm.carbonPrefix + "--modal-content--with-form")] = _vm.hasFormContent, _obj$2 )],attrs:{"tabindex":_vm.scrollable ? 0 : undefined}},[_vm._t("content")],2),(_vm.hasFooter)?_c('cv-button-set',{class:[
        (_vm.carbonPrefix + "--modal-footer"),
        ( _obj$3 = {}, _obj$3[(_vm.carbonPrefix + "--modal-footer--three-button")] = _vm.hasPrimary && _vm.hasSecondary && _vm.hasOtherBtn, _obj$3 ) ]},[(_vm.hasOtherBtn)?_c('cv-button',{ref:"otherBtn",attrs:{"type":"button","kind":"secondary"},on:{"click":_vm.onOtherBtnClick}},[_vm._t("other-button",function(){return [_vm._v("Other button")]})],2):_vm._e(),(_vm.hasSecondary)?_c('cv-button',{ref:"secondary",attrs:{"type":"button","kind":"secondary"},on:{"click":_vm.onSecondaryClick}},[_vm._t("secondary-button",function(){return [_vm._v("Secondary button")]})],2):_vm._e(),(_vm.hasPrimary)?_c('cv-button',{ref:"primary",attrs:{"disabled":_vm.primaryButtonDisabled,"type":"button","kind":_vm.primaryKind},on:{"click":_vm.onPrimaryClick}},[_vm._t("primary-button",function(){return [_vm._v("Primary button")]})],2):_vm._e()],1):_vm._e(),_c('div',{ref:"afterContent",staticClass:"cv-modal__after-content",staticStyle:{"position":"absolute","height":"1px","width":"1px","left":"-9999px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusAfterContent}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }