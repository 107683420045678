var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("cv-overflow-menu " + _vm.carbonPrefix + "--overflow-menu"),attrs:{"data-overflow-menu":"","id":_vm.uid}},[_c('button',{ref:"trigger",class:[
      (_vm.carbonPrefix + "--overflow-menu__trigger " + _vm.carbonPrefix + "--tooltip__trigger"),
      (_vm.carbonPrefix + "--tooltip--a11y"),
      ( _obj = {}, _obj[((this.carbonPrefix) + "--tooltip--" + _vm.tipPosition)] = _vm.label, _obj[((this.carbonPrefix) + "--tooltip--align-" + _vm.tipAlignment)] = _vm.label, _obj[(_vm.carbonPrefix + "--overflow-menu--open")] = _vm.open, _obj ) ],attrs:{"aria-haspopup":"","type":"button","aria-expanded":_vm.open ? 'true' : 'false',"aria-controls":(_vm.uid + "-menu"),"id":(_vm.uid + "-trigger")},on:{"click":_vm.doToggle,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.doToggle.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onOverflowMenuTab.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.doToggle.apply(null, arguments)}}},[(_vm.label)?_c('span',{class:(_vm.carbonPrefix + "--assistive-text")},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._t("trigger",function(){return [_c('OverflowMenuVertical16',{class:(_vm.carbonPrefix + "--overflow-menu__icon")})]})],2),_c('div',{ref:"popup",class:[
      (_vm.carbonPrefix + "--overflow-menu-options"),
      ( _obj$1 = {}, _obj$1[(_vm.carbonPrefix + "--overflow-menu--flip")] = _vm.flipMenu, _obj$1[(_vm.carbonPrefix + "--overflow-menu-options--open")] = _vm.open, _obj$1 ) ],style:({ left: _vm.left + 'px', top: _vm.top + 'px' }),attrs:{"tabindex":"-1","aria-labelledby":(_vm.uid + "-trigger"),"id":(_vm.uid + "-menu")},on:{"focusout":_vm.checkFocusOut,"mousedown":function($event){$event.preventDefault();return _vm.preventFocusOut.apply(null, arguments)}}},[_c('div',{ref:"beforeContent",staticClass:"cv-overflow-menu__before-content",staticStyle:{"position":"absolute","height":"1px","width":"1px","left":"-9999px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusBeforeContent}}),_c('ul',{class:(_vm.carbonPrefix + "--overflow-menu-options__content")},[_vm._t("default")],2),_c('div',{ref:"afterContent",staticClass:"cv-overflow-menu__after-content",staticStyle:{"position":"absolute","height":"1px","width":"1px","left":"-9999px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusAfterContent}})])])}
var staticRenderFns = []

export { render, staticRenderFns }