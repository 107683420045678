var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cv-wrapper',{class:("cv-select " + _vm.carbonPrefix + "--form-item"),attrs:{"tag-type":_vm.formItem ? 'div' : ''}},[_c('div',{class:[
      (_vm.carbonPrefix + "--select"),
      ( _obj = {
        'cv-select': !_vm.formItem
      }, _obj[(_vm.carbonPrefix + "--select--inline")] = _vm.inline, _obj[(_vm.carbonPrefix + "--select--light")] = _vm.isLight, _obj[(_vm.carbonPrefix + "--select--invalid")] = _vm.isInvalid, _obj[(_vm.carbonPrefix + "--select--disabled")] = _vm.$attrs.disabled, _obj ) ],attrs:{"data-test":_vm.value}},[_c('label',{class:[
        (_vm.carbonPrefix + "--label"),
        ( _obj$1 = {}, _obj$1[(_vm.carbonPrefix + "--visually-hidden")] = _vm.hideLabel, _obj$1[(_vm.carbonPrefix + "--label--disabled")] = _vm.$attrs.disabled, _obj$1 ) ],attrs:{"for":_vm.uid}},[_vm._v(_vm._s(_vm.label))]),_c('cv-wrapper',{class:(_vm.carbonPrefix + "--select-input--inline__wrapper"),attrs:{"tag-type":_vm.inline ? 'div' : ''}},[_c('div',{class:(_vm.carbonPrefix + "--select-input__wrapper"),attrs:{"data-invalid":_vm.isInvalid}},[_c('select',_vm._g(_vm._b({ref:"select",class:(_vm.carbonPrefix + "--select-input"),attrs:{"id":_vm.uid},domProps:{"value":_vm.internalValue}},'select',_vm.$attrs,false),_vm.inputListeners),[_vm._t("default")],2),_c('chevron-down-glyph',{class:(_vm.carbonPrefix + "--select__arrow")}),(_vm.isInvalid)?_c('warning-filled-16',{class:(_vm.carbonPrefix + "--select__invalid-icon")}):_vm._e()],1),(_vm.isInvalid)?_c('div',{class:(_vm.carbonPrefix + "--form-requirement")},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage))]})],2):_vm._e(),(!_vm.isInvalid && !_vm.inline && _vm.isHelper)?_c('div',{class:(_vm.carbonPrefix + "--form__helper-text")},[_vm._t("helper-text",function(){return [_vm._v(_vm._s(_vm.helperText))]})],2):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }