var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("cv-text-area " + _vm.carbonPrefix + "--form-item")},[_c('label',{class:[
      (_vm.carbonPrefix + "--label"),
      ( _obj = {}, _obj[(_vm.carbonPrefix + "--label--disabled")] = _vm.$attrs.disabled !== undefined && _vm.$attrs.disabled, _obj ) ],attrs:{"for":_vm.uid}},[_vm._v(_vm._s(_vm.label))]),_c('div',{class:(_vm.carbonPrefix + "--text-area__wrapper"),attrs:{"data-invalid":_vm.isInvalid}},[(_vm.isInvalid)?_c('WarningFilled16',{class:(_vm.carbonPrefix + "--text-area__invalid-icon")}):_vm._e(),_c('textarea',_vm._g(_vm._b({ref:"textarea",class:[
        (_vm.carbonPrefix + "--text-area"),
        ( _obj$1 = {}, _obj$1[(_vm.carbonPrefix + "--text-area--light")] = _vm.isLight, _obj$1[(_vm.carbonPrefix + "--text-area--invalid")] = _vm.isInvalid, _obj$1 ) ],attrs:{"aria-invalid":_vm.isInvalid,"aria-describedby":_vm.isInvalid ? _vm.errorId : undefined,"id":_vm.uid},domProps:{"value":_vm.value}},'textarea',_vm.$attrs,false),_vm.inputListeners))],1),(_vm.isInvalid)?_c('div',{class:(_vm.carbonPrefix + "--form-requirement"),attrs:{"id":_vm.errorId}},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage))]})],2):_vm._e(),(_vm.isHelper)?_c('div',{class:[
      (_vm.carbonPrefix + "--form__helper-text"),
      ( _obj$2 = {}, _obj$2[(_vm.carbonPrefix + "--form__helper-text--disabled")] = _vm.$attrs.disabled, _obj$2 ) ]},[_vm._t("helper-text",function(){return [_vm._v(_vm._s(_vm.helperText))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }