var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    "cv-text-input",
    (_vm.carbonPrefix + "--form-item"),
    (_vm.carbonPrefix + "--text-input-wrapper"),
    ( _obj = {}, _obj[(_vm.carbonPrefix + "--password-input-wrapper")] = _vm.isPassword, _obj ) ]},[_c('label',{class:[
      (_vm.carbonPrefix + "--label"),
      ( _obj$1 = {}, _obj$1[(_vm.carbonPrefix + "--label--disabled")] = _vm.$attrs.disabled !== undefined && _vm.$attrs.disabled, _obj$1 ) ],attrs:{"for":_vm.uid}},[_vm._v(_vm._s(_vm.label))]),_c('div',{class:[
      (_vm.carbonPrefix + "--text-input__field-wrapper"),
      ( _obj$2 = {}, _obj$2[(_vm.carbonPrefix + "--text-input__field-wrapper--warning")] = !_vm.isInvalid && _vm.isWarn, _obj$2 ) ],attrs:{"data-invalid":_vm.isInvalid}},[(_vm.isInvalid)?_c('WarningFilled16',{class:(_vm.carbonPrefix + "--text-input__invalid-icon")}):_vm._e(),(_vm.isWarn)?_c('WarningAltFilled16',{class:(_vm.carbonPrefix + "--text-input__invalid-icon " + _vm.carbonPrefix + "--text-input__invalid-icon--warning")}):_vm._e(),_c('input',_vm._g(_vm._b({ref:"input",class:[
        (_vm.carbonPrefix + "--text-input"),
        ( _obj$3 = {}, _obj$3[(_vm.carbonPrefix + "--text-input--light")] = _vm.isLight, _obj$3[(_vm.carbonPrefix + "--text-input--invalid")] = _vm.isInvalid, _obj$3[(_vm.carbonPrefix + "--text-input--warning")] = _vm.isWarn, _obj$3[(_vm.carbonPrefix + "--password-input")] = _vm.isPassword, _obj$3 ) ],attrs:{"id":_vm.uid,"data-toggle-password-visibility":_vm.isPassword,"type":_vm.dataType},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.inputListeners)),(_vm.isPassword)?_c('button',{class:[
        (_vm.carbonPrefix + "--btn"),
        (_vm.carbonPrefix + "--text-input--password__visibility__toggle"),
        (_vm.carbonPrefix + "--tooltip__trigger"),
        (_vm.carbonPrefix + "--tooltip--a11y"),
        (_vm.carbonPrefix + "--tooltip--bottom"),
        (_vm.carbonPrefix + "--tooltip--align-center") ],attrs:{"type":"button"},on:{"click":_vm.togglePasswordVisibility}},[_c('span',{class:(_vm.carbonPrefix + "--assistive-text")},[_vm._v(_vm._s(_vm.passwordHideShowLabel))]),(_vm.isPasswordVisible)?_c('ViewOff16',{class:(_vm.carbonPrefix + "--icon-visibility-off")}):_c('View16',{class:(_vm.carbonPrefix + "--icon-visibility-off")})],1):_vm._e()],1),(_vm.isInvalid)?_c('div',{class:(_vm.carbonPrefix + "--form-requirement")},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage))]})],2):_vm._e(),(_vm.isWarn)?_c('div',{class:(_vm.carbonPrefix + "--form__requirement")},[_vm._t("warn-text",function(){return [_vm._v(_vm._s(_vm.warnText))]})],2):_vm._e(),(_vm.isHelper)?_c('div',{class:[
      (_vm.carbonPrefix + "--form__helper-text"),
      ( _obj$4 = {}, _obj$4[(_vm.carbonPrefix + "--form__helper-text--disabled")] = _vm.$attrs.disabled, _obj$4 ) ]},[_vm._t("helper-text",function(){return [_vm._v(_vm._s(_vm.helperText))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }