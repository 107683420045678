var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[
    'cv-inline-notification',
    (_vm.carbonPrefix + "--inline-notification"),
    (_vm.carbonPrefix + "--inline-notification--" + (_vm.kind.toLowerCase())),
    ( _obj = {}, _obj[(_vm.carbonPrefix + "--inline-notification--low-contrast")] = _vm.lowContrast, _obj ) ],attrs:{"data-notification":"","role":_vm.isAlert ? 'alert' : false,"aria-live":!_vm.isAlert ? 'polite' : false}},_vm.$listeners),[_c('div',{class:(_vm.carbonPrefix + "--inline-notification__details")},[_c(_vm.icon,{tag:"component",class:(_vm.carbonPrefix + "--inline-notification__icon")}),_c('div',{class:(_vm.carbonPrefix + "--inline-notification__text-wrapper")},[_c('p',{class:(_vm.carbonPrefix + "--inline-notification__title")},[_vm._v(_vm._s(_vm.title))]),_c('p',{class:(_vm.carbonPrefix + "--inline-notification__subtitle"),domProps:{"innerHTML":_vm._s(_vm.subTitle)}})])],1),(_vm.actionLabel)?_c('button',{class:[
      (_vm.carbonPrefix + "--inline-notification__action-button"),
      (_vm.carbonPrefix + "--btn"),
      (_vm.carbonPrefix + "--btn--sm"),
      (_vm.carbonPrefix + "--btn--ghost") ],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._v("\n    "+_vm._s(_vm.actionLabel)+"\n  ")]):_vm._e(),(!_vm.hideCloseButton)?_c('button',{class:(_vm.carbonPrefix + "--inline-notification__close-button"),attrs:{"type":"button","aria-label":_vm.closeAriaLabel,"data-notification-btn":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('Close20',{class:(_vm.carbonPrefix + "--inline-notification__close-icon")})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }